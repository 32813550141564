export default {
  LAST_LOGGED_IN_USER_NAME: "LAST_LOGGED_IN_USER_NAME",
  USER_ID: "USER_ID",
  STATE_UI: "STATE_UI",
  LAST_NAVIGATION_ROUTE: "LAST_NAVIGATION_ROUTE",
  ADDRESS_ADVANCED_HISTORY: "ADDRESS_ADVANCED_HISTORY",
  GRID_STATE: "GRID_STATE_",
  GRID_GROUP_BY_OPTION: "GRID_GROUP_BY_OPTION_",
  GRID_FILTER_BY_OPTION: "GRID_FILTER_BY_OPTION_",
  FILTERS_CLEARED: "FILTERS_CLEARED",
  TERMS_CONDITIONS_ACCEPTED: "TERMS_CONDITIONS_ACCEPTED",
  PODS_TASK_GROUPS: "PODS_TASK_GROUPS",
  USER_TAGS: "USER_TAGS",
  ACCOUNT_COMPLIANCE: "ACCOUNT_COMPLIANCE",
  TRUSTED_DEVICE: "TRUSTED_DEVICE",
  TRUSTED_DEVICE_ID: "TRUSTED_DEVICE_ID",
  MOBILE_EMBEDDED_LAYOUT: "MOBILE_EMBEDDED_LAYOUT",
  LIMITED_LIFETIME_TOKEN: "LIMITED_LIFETIME_TOKEN",
  OKTA_TOKEN_RENEWAL_LOCK: "OKTA_TOKEN_RENEWAL_LOCK",
  USER_TOKEN: "USER_TOKEN",
  UAM_ACTIVE_ACCOUNT_ID: "UAM_ACTIVE_ACCOUNT_ID",
  ACTIVITY_LOGS_ENABLED: "ACTIVITY_LOGS_ENABLED",
  CUSTOM_FIELDS_ACTIVE_ACCOUNT_TYPE: "CUSTOM_FIELDS_ACTIVE_ACCOUNT_TYPE",
};
