import axios from 'axios'
import TokenService from '../services/tokenService'
import _isNumber from 'lodash/isNumber'
import _get from 'lodash/get'
import CamundaApiService from '../services/camundaApi'
import BugsnagPerformance from '@bugsnag/browser-performance'

const CancelToken = axios.CancelToken;

const Client = {
    axiosClient: axios.create(),
    _requestId: 0,
    cancellationSources: {},
    getMargins() {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/margins`;
        return this.get(path);
    },
    claimStartInspection(transportationOrderId, taskId, vtoId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/claims/${transportationOrderId}/tasks/${taskId}/vehicle-transfer-orders/${vtoId}/start-inspection`;
        return this.post(path, {});
    },

    claimCompleteInspection(transportationOrderId, taskId, vtoId, inspectionId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/claims/${transportationOrderId}/tasks/${taskId}/vehicle-transfer-orders/${vtoId}/inspection/${inspectionId}/complete`;
        return this.post(path, {});
    },

    updateInspectionPoints(inspectionPointsData, transportationOrderId, taskId) {
        let token = TokenService.getToken();
        let data = new FormData();

        let pathPrefix = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/`;
        let path = `${pathPrefix}${transportationOrderId}/tasks/${taskId}/vehicle-transfer-orders/${_get(inspectionPointsData, "vtoId")}/inspection/${_get(inspectionPointsData, "inspectionId")}/inspection-points`;

        data.set('inspection-points', JSON.stringify(_get(inspectionPointsData, "inspectionPoints", [])));

        if (inspectionPointsData.files) {
            inspectionPointsData.files.forEach(file => {
              data.append('files', file);
            })
        }

        return axios({
            method: 'post',
            url: path,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
    },

    attachImageToVehicleTransferOrder(file, transportationOrderId, inspectionPointKey, inspectionId, taskId, vtoId, vin, description, geolocation, isStartInspection, labels, isClaim) {
        let token = TokenService.getToken();

        let data = new FormData();

        let metadata = {
            description: description,
            geolocation: geolocation,
        };

        if (file) {
            data.set('file', file);
            metadata.name = file.type === 'application/pdf' ? 'pdf' : ''
        }

        if (labels) {
            metadata.labels = labels;
        }

        data.set('metadata', JSON.stringify(metadata));
        data.set('inspection-point', inspectionPointKey);

        let path;
        let pathPrefix;
        if (isClaim) {
            pathPrefix = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/claims/`;
        } else {
            pathPrefix = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/`;
        }

        if (isStartInspection) {
            path = `${pathPrefix}${transportationOrderId}/tasks/${taskId}/vehicle-transfer-orders/${vtoId}/start-inspection`;

            data.set('vin', vin);
        } else {
            data.set('inspectionId', inspectionId);
            path = `${pathPrefix}${transportationOrderId}/tasks/${taskId}/vehicle-transfer-orders/${vtoId}/inspection/${inspectionId}/attach-image`;
        }

        return axios({
            method: 'post',
            url: path,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
    },

    attachDocumentToVehicleTransferOrder(file, transportationOrderId, inspectionPointKey, inspectionId, taskId, vtoId, vin, description, geolocation, isStartInspection, labels, isClaim) {
        let token = TokenService.getToken();

        let data = new FormData();

        let metadata = {
            description: description,
            geolocation: geolocation,
        };

        if (file) {
            data.set('file', file);
            metadata.name = file.type === 'application/pdf' ? 'pdf' : ''
        }

        if (labels) {
            metadata.labels = labels;
        }

        data.set('metadata', JSON.stringify(metadata));
        data.set('inspection-point', inspectionPointKey);

        let path;
        let pathPrefix;
        if (isClaim) {
            pathPrefix = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/claims/`;
        } else {
            pathPrefix = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/`;
        }

        if (isStartInspection) {
            path = `${pathPrefix}${transportationOrderId}/tasks/${taskId}/vehicle-transfer-orders/${vtoId}/start-inspection`;

            data.set('vin', vin);
        } else {
            data.set('inspectionId', inspectionId);
            path = `${pathPrefix}${transportationOrderId}/tasks/${taskId}/vehicle-transfer-orders/${vtoId}/inspection/${inspectionId}/attach-document`;
        }

        return axios({
            method: 'post',
            url: path,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
    },
    createGatePass(file) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/gate-passes`;
        let token = TokenService.getToken();

        let data = new FormData();

        data.set('file', file);

        return axios({
            method: 'post',
            url: path,
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
    },
    createRationale(data) {
        if (data.roles) {
            let roles = [];
            data.roles.forEach((role) => roles.push(role.trim()));
            data.roles = roles;
        }
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/rationales`;
        return this.post(path, data);
    },
    patchRationale(id, data) {
        if (data.roles) {
            let roles = [];
            data.roles.forEach((role) => roles.push(role.trim()));
            data.roles = roles;
        }
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/rationales/${id}`;
        return this.patch(path, data);
    },
    createOrderNote(id, data, perspective) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/${perspective}/${id}/comments`;
        return this.post(path, data);
    },
    updateOrderNote(id, data, perspective, commentId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/${perspective}/${id}/comments/${commentId}`;
        return this.patch(path, data);
    },
    getShareLink(id, role, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${id}/portal/${role}/short`;
        return this.post(path, data);
    },

    getExportsList() {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/table-data-export`;
        return this.get(path);
    },

    downloadExportFile(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/table-data-export/${id}/download`;
        return this.get(path);
    },

    deleteExportFile(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/table-data-export/${id}`;
        return this.delete(path);
    },

    deleteRationale(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/rationales/${id}`;
        return this.delete(path);
    },
    createBulkOrder(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/draft/bulk`;
        return this.post(path, data);
    },
    updateDraftVTO(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/draft/${id}`;
        return this.put(path, data);
    },
    deleteDraftVTOBulk(ids) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/draft/bulk/delete`;
        return this.post(path, ids);
    },
    getOrders() {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders`;
        return this.get(path);
    },
    getOrderByIdFull(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${id}/full`;
        return this.get(path);
    },
    getOrderById(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${id}`;
        return this.get(path);
    },
    createOrder(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders`;
        return this.post(path, data);
    },
    patchOrder(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${id}`;
        return this.patch(path, data);
    },
    patchTransportationOrderPayout(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${id}/payout/items`;
        return this.patch(path, data);
    },
    patchTransportationOrderPayoutProperty(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${id}/payout`;
        return this.patch(path, data);
    },
    patchOrderFare(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${id}/fare/items`;
        return this.patch(path, data);
    },
    updateOrderFare(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${id}/change-fare`;
        return this.patch(path, data);
    },
    createOrderDraft(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/order-drafts`;
        return this.post(path, data);
    },
    updateOrderDraft(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/order-drafts/${id}`;
        return this.put(path, data);
    },
    getOrderDraftById(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/order-drafts/${id}`;
        return this.get(path);
    },
    startClaimFlow(orderId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/claims`;
        return this.post(path, {
            orderId
        });
    },
    startClaimFlowDirect(orderId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/camunda/api/engine/engine/default/process-definition/ClaimsMainProcess:1:6e3771eb-b3a0-11ed-ba75-8e5969b698ad/submit-form`;
        return this.post(path, {
            "businessKey": orderId,
            "variables": {}
        });
    },
    quoteOrder(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/quote`;
        return this.post(path, data);
    },
    customerQuoteOrderDraft(data, id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/quotes/order-drafts/${id}/customer`;
        return this.post(path, data);
    },
    transporterQuoteOrderDraft(data, id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/quotes/order-drafts/${id}/transporter`;
        return this.post(path, data);
    },
    customerQuoteOrder(data, id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/quotes/orders/${id}/customer`;
        return this.post(path, data);
    },
    transporterQuoteOrder(data, id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/quotes/orders/${id}/transporter`;
        return this.post(path, data);
    },
    replaceOrder(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${id}/replace`;
        return this.post(path, data);
    },
    cancelOrder(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${id}/cancel`;
        return this.post(path, data);
    },
    createOrderChangeRequestTask(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${id}/change-request`;
        return this.post(path, data);
    },
    createTransportationOrderChangeRequestTask(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${id}/change-request`;
        return this.post(path, data);
    },
    changeVehicleFromTransportationOrderVTO(data, id, vtoId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${id}/vehicle-transfer-orders/${vtoId}/change-vehicle`;
        return this.post(path, data);
    },
    getTransportationOrderById(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${id}`;
        return this.get(path);
    },
    getOrderMessages(orderId, sort) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${orderId}/messages`;
        let query = [];

        if (sort) {
            query.push(`sort=${sort}`);
        } else {
            query.push('sort=created.date,asc');
        }
        path += `?${query.join('&')}`;

        return this.get(path);
    },
    getMessage(messageId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/messages/${messageId}`;
        return this.get(path);
    },
    getTransportationOrderMessages(transportationOrderId, orderId, sort) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${transportationOrderId}/orders/${orderId}/messages`;
        let query = [];

        if (sort) {
            query.push(`sort=${sort}`);
        } else {
            query.push('sort=created.date,asc');
        }
        path += `?${query.join('&')}`;

        return this.get(path);
    },
    createOrderMessage(orderId, message, transportationOrderId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${orderId}/messages`;
        return this.post(path, {
            orderId: orderId,
            text: message,
            transporterOrderId: transportationOrderId
        });
    },
    createTransportationOrderMessage(transportationOrderId, orderId, message) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${transportationOrderId}/orders/${orderId}/messages`;
        return this.post(path, {
            orderId: orderId,
            text: message,
            transporterOrderId: transportationOrderId
        });
    },
    getDefaultServices() {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/quotes/services`;
        return this.get(path);
    },
    getTransportationOrderByIdFull(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${id}/full`;
        return this.get(path);
    },
    getVehicleTransferOrderById(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}`;
        return this.get(path);
    },
    getVehicleTransferOrderPhotosById(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/photos`;
        return this.get(path);
    },
    getVehicleTransferOrderPhotoByPhotoId(vtoId, photoId, size) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${vtoId}/photos/${photoId}/${size}`;
        return this.getFile(path);
    },
    getVehicleTransferOrderGatePassesById(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/gate-passes`;
        return this.get(path);
    },
    getVehicleTransferOrder(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}`;
        return this.get(path);
    },
    getVehicleTransferOrderFull(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/full`;
        return this.get(path);
    },
    getVehicleTransferOrderGatePasses(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/gate-passes`;
        return this.get(path);
    },
    getVehicleTransferOrderGatePass(id, passId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/gate-passes/${passId}`;
        return this.getFile(path);
    },
    getVehicleTransferOrderPhotos(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/photos`;
        return this.get(path);
    },
    getVehicleTransferOrderPhoto(id, photoId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/photos/${photoId}`;
        return this.get(path);
    },
    getVehicleTransferOrderPhotosForTask(id, taskName) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/photos/task-name/${taskName}`;
        return this.get(path);
    },
    getVehicleTransferOrderInspectionReports(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/inspections`;
        return this.get(path);
    },
    getVehicleTransferOrderInspectionReport(id, inspectionId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/inspections/${inspectionId}`;
        return this.get(path);
    },
    getVehicleTransferOrderInspectionReportForTask(id, taskName) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/inspections/tasks/${taskName}`;
        return this.get(path);
    },
    getTransportationOrderBillOfLadingDocument(transportationOrderId, billOfLadingId, htmlVersion) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${transportationOrderId}/bills-of-lading/${billOfLadingId}`;
        if (htmlVersion) {
            path += '?attachment=false&contentType=text/html';
        }
        return this.getFile(path);
    },
    getTransportationOrderBillOfLadingDocumentPreview(transportationOrderId, htmlVersion) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${transportationOrderId}/bills-of-lading/preview`;
        if (htmlVersion) {
            path += '?attachment=false&contentType=text/html';
        }
        return this.getFile(path);
    },
    downloadFile(uri) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/${uri}`;
        return this.getFile(path);
    },
    getShipperOrder(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${id}`;
        return this.get(path);
    },
    getShipperOrderFull(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${id}/full`;
        return this.get(path);
    },
    getTransporterOrder(id) {
        return this.getTransportationOrder(id);
    },
    getTransportationOrderTasks(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${id}/tasks`;
        return this.get(path);
    },
    detachVehicleTransferOrder(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/detach`;
        return this.post(path);
    },
    completeTransportationOrderTask(taskId, actionName, actionVariables) {
        let encodedVariables = {};

        Object.keys(actionVariables).forEach((key) => {
            let value = actionVariables[key];
            encodedVariables[key] = CamundaApiService.encodeVariable(value);
        });

        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/tasks/${taskId}/complete`;
        return this.post(path, {
            name: actionName,
            variables: encodedVariables
        });
    },
    completeOrderTask(taskId, actionName, actionVariables) {
        let encodedVariables = {};

        Object.keys(actionVariables).forEach((key) => {
            let value = actionVariables[key];
            encodedVariables[key] = CamundaApiService.encodeVariable(value);
        });

        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/tasks/${taskId}/complete`;
        return this.post(path, {
            name: actionName,
            variables: encodedVariables
        });
    },
    completeOrderTaskDraft(taskId, actionName, actionVariables) {

        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/tasks/${taskId}/complete`;
        return this.post(path, {
            name: actionName,
            variables: actionVariables
        });
    },
    getVehicleTransferOrders(page, size) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return this.get(path);
    },
    getShipperOrders(page, size) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return this.get(path);
    },
    getVehicleTransferOrdersByCompanyId(companyId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/search?createdByCompanyId=${companyId}&size=2000`;
        return this.get(path);
    },
    getTransportationOrder(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${id}`;
        return this.get(path);
    },
    getTransportationOrderActivityLogs(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${id}/activity-logs`;
        return this.get(path);
    },
    updateTransportationOrderAdditionalProperties(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${id}/additionalProperties`;
        return this.patch(path, data);
    },
    getFeatureAccessForKey(key) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/feature-access/${key}/by-user`;
        return this.get(path);
    },
    getFeatureAccessForKeys(keys) {
        let keysQuery = '';

        keys.forEach(function (key) {
            keysQuery += `&ids=${key}`;
        });

        keysQuery = keysQuery.substring(1);

        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/feature-access/by-user?${keysQuery}`;
        return this.get(path);
    },
    getExpandedOrder(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/${id}/expanded`;
        return this.get(path);
    },
    getDriverLocations(query = {}) {
        let queryParams = Object.keys(query).map((key) => {
            return `${key}=${query[key]}`
        }).join('&');

        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/driver-locations?${queryParams}`;
        return this.get(path);
    },
    getDriverLocationsByEmail(email) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/driver-locations/${email}`;
        return this.get(path);
    },
    getDriverLocationsNearOrderId(orderId, maxDistanceMiles) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/driver-locations/search/near-order/${orderId}?maxDistanceMiles=${maxDistanceMiles}`;
        return this.get(path);
    },
    getExpandedOrders(page, size, params = {}) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/expanded`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return this.get(path);
    },
    getTransportationOrders(page, size) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return this.get(path);
    },
    getTransportationOrdersFull(page, size, params = {}) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/full`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return this.get(path);
    },
    getTransportationOrdersRsql(page, size, params = {}) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/rsql`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return this.get(path);
    },
    getOrdersFSearch(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.tasksManagementApi.path')}/search`;
        return this.post(path, data);
    },
    getShipperOrdersFull(page, size, params = {}) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/full`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return this.get(path);
    },
    getVehicleTransfersOrdersFull(page, size, params = {}) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicle-transfer-orders/full`;

        let query = [];

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return this.get(path);
    },
    getFullPayload(type, page, size, sort, params = {}, rql = []) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/{type}/full`
            .replace("{type}", type);

        let query = [];
        if (rql.length > 0) {
            params['query'] = rql.join(";");
        }

        if (_isNumber(page)) {
            query.push(`page=${page}`)
        }

        if (size) {
            query.push(`size=${size}`)
        }

        if (sort) {
            query.push(`sort=${sort}`)
        }

        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        console.log(path);
        return this.get(path);

    },
    getPaymentsFull(page, size, sort, params = {}, rql = []) {
        return this.getFullPayload('payments', page, size, sort, params, rql);
    },
    getChargesFull(page, size, sort, params = {}, rql = []) {
        return this.getFullPayload('charges', page, size, sort, params, rql);
    },
    getPayoutsFull(page, size, sort, params = {}, rql = []) {
        return this.getFullPayload('payouts', page, size, sort, params, rql);
    },
    getSummaryPayload(type, params = {}, rql = []) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/{type}/summary`
            .replace("{type}", type);

        let query = [];
        if (rql.length > 0) {
            params['query'] = rql.join(";");
        }
        Object.keys(params).forEach((key) => {
            let value = params[key];
            query.push(`${key}=${value}`);
        });

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }
        return this.get(path);
    },
    getPaymentsSummary(params = {}, rql = []) {
        return this.getSummaryPayload('payments', params, rql);
    },
    getChargesSummary(params = {}, rql = []) {
        return this.getSummaryPayload('charges', params, rql);
    },
    getPayoutsSummary(params = {}, rql = []) {
        return this.getSummaryPayload('payouts', params, rql);
    },
    getOrdersPaginated() {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders`;
        return this.get(path);
    },
    getVehicleByVin(vin) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/vehicles/${vin}`;
        return this.get(path);
    },
    getFile(path) {
        let params = {};
        let query = [];
        let isTextHtmlFormat = path.includes("contentType=text/html");
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }
        if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
            headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
            headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
        }

        if (TokenService.hasSpecifiedRegistrationIds()) {
            headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
        }

        for (let key in params) {
            query.push(`${key}=${params[key]}`);
        }
        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', path, true);
            for (let key in headers) {
                xhr.setRequestHeader(key, headers[key]);
            }
            xhr.responseType = 'blob';
            xhr.onload = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const reader = new FileReader();
                    isTextHtmlFormat ? reader.readAsText(xhr.response) : reader.readAsDataURL(xhr.response);
                    reader.onloadend = () => resolve(reader.result);
                } else {
                    reject({code: _get(xhr, "status"), message: _get(xhr, "statusText")});
                }
            };
            xhr.onerror = e => reject(new Error('There was a network error.', e));
            xhr.ontimeout = e => reject(new Error('There was a timeout error.', e));
            xhr.send(params);
        });
    },
    // campaigns
    createNewCampaign(campaign) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/campaigns/`;
        return this.post(path, campaign);
    },
    getAllCampaigns(size, params) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/campaigns/`;
        let query = [];
        if (size) {
            query.push(`size=${size}`)
        }

        if (params) {
            query.push(`query=type==${params}`)
        }

        if (query.length > 0) {
            path += `?${query.join('&')}`;
        }

        return this.get(path);
    },
    getAllMobilityStatuses() {
      let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/mobility-statuses`;
      return this.get(path);
    },
    updateCampaignWithID(id, campaign) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/campaigns/${id}`;
        return this.put(path, campaign);
    },
    removeCampaignWithID(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/campaigns/${id}`;
        return this.delete(path);
    },
    driversSummary(query) {
        let queryString = Object.keys(query)
            .map((key) => {
                return `${key}=${query[key]}`
            })
            .join('&');

        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/summary/drivers?${queryString}`;
        return this.get(path);
    },


    // tasks
    updateTransportationOrderTask(taskId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/tasks/${taskId}`;
        return this.put(path, data);
    },
    updateOrderTask(taskId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/tasks/${taskId}`;
        return this.put(path, data);
    },
    updateTask(taskId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/tasks/${taskId}`;
        return this.put(path, data);
    },
    getTransportationOrderTaskComments(transportationOrderId, taskId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${transportationOrderId}/tasks/${taskId}/comment`;
        return this.get(path);
    },
    createTransportationOrderTaskComment(transportationOrderId, taskId, message) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${transportationOrderId}/tasks/${taskId}/comment`;
        return this.post(path, {
            message
        });
    },
    getTaskComments(taskId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/tasks/${taskId}/comment`;
        return this.get(path);
    },
    createTaskComment(taskId, message) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/tasks/${taskId}/comment`;
        return this.post(path, {
            message
        });
    },
    createOrderTaskComment(orderId, taskId, message) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${orderId}/tasks/${taskId}/comment`;
        return this.post(path, {
            message
        });
    },
    createTransportationOrderTaskTask(transportationOrderId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${transportationOrderId}/tasks`;
        return this.post(path, data);
    },
    createOrderTaskTask(orderId, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${orderId}/tasks`;
        return this.post(path, data);
    },

    tenantsProvision(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/tenants/provision`;
        return this.post(path, data);
    },

    getRationales(group) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/rationales`;

        if (group) {
            path = `${path}?group=${group}`;
        }

        return this.get(path);
    },

    getTask(taskId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/tasks/${taskId}`;
        return this.get(path);
    },

    getTasksConfigurations() {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/task-configs/`;
        return this.get(path);
    },

    getTaskConfiguration(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/task-configs/${id}`;
        return this.get(path);
    },

    getTasksActiveConfiguration() {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/task-configs/active`;
        return this.get(path);
    },

    createTaskConfiguration(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/task-configs`;
        return this.post(path, data);
    },

    updateTaskConfiguration(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/task-configs/${id}`;
        return this.put(path, data);
    },

    removeTaskConfiguration(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/task-configs/${id}`;
        return this.delete(path);
    },

    getTaskConfigurationVariableGroups() {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/task-config-variable-groups`;
        return this.get(path);
    },

    getTaskConfigurationVariableGroup(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/task-config-variable-groups/${id}`;
        return this.get(path);
    },
    createTaskConfigurationVariableGroup(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/task-config-variable-groups`;
        return this.post(path, data);
    },
    updateTaskConfigurationVariableGroup(id, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/task-config-variable-groups/${id}`;
        return this.put(path, data);
    },
    removeTaskConfigurationVariableGroup(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/task-config-variable-groups/${id}`;
        return this.delete(path);
    },

    getOrderGenericTasks(orderId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/processes/${orderId}/tasks/generic`;
        return this.get(path);
    },

    getOrderGenericTaskSubtasks(orderId, taskKey) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/processes/${orderId}/tasks/generic/${taskKey}/subtasks`;
        return this.get(path);
    },

    createOrderGenericTask(orderId, taskKey, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/processes/${orderId}/tasks/${taskKey}`;
        return this.post(path, data);
    },

    createOrderGenericTaskSubtask(orderId, taskKey, taskType, subTaskType, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/processes/${orderId}/tasks/${taskKey}/type/${taskType}/subtype/${subTaskType}`;
        return this.post(path, data);
    },

    createOrderGenericTaskAndSubtasks(orderId, taskKey, taskType, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/processes/${orderId}/tasks/${taskKey}/type/${taskType}`;
        return this.post(path, data);
    },


    completeTask(taskId, actionName, actionVariables) {
        let encodedVariables = {};

        Object.keys(actionVariables).forEach((key) => {
            let value = actionVariables[key];
            encodedVariables[key] = CamundaApiService.encodeVariable(value);
        });

        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/tasks/${taskId}/complete`;
        return this.post(path, {
            name: actionName,
            variables: encodedVariables
        });
    },

    closeOrder(orderId, actionVariables) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${orderId}/proceed-close`;
        return this.post(path, actionVariables);
    },

    payoutOrder(orderId, actionVariables) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${orderId}/proceed-payout`;
        return this.post(path, actionVariables);
    },

    createOrderPayoutChangeTask(orderId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/orders/${orderId}/fare-change-task`;
        return this.post(path);
    },

    createTransportationOrderPayoutChangeTask(transportationOrderId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${transportationOrderId}/payout-change-task`;
        return this.post(path);
    },

    createTransportationOrderFromVehicleTransferOrders(data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders`;
        return this.post(path, data);
    },

    appendVehiclesInTpOrder(transportationOrderId, vehicleTransferOrderIDs) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${transportationOrderId}/append`;
        return this.post(path, {
            vehicleTransferOrders: vehicleTransferOrderIDs.map((id) => {
                return {
                    id: id
                }
            })
        });
    },

    getSwappableVehicleTransferOrdersForTransportationOrderId(transportationOrderId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${transportationOrderId}/swap/vtos`;
        return this.get(path);
    },

    getStatistics(type, statistic, params = {}) {
        const queryString = Object.keys(params)
            .map((key) => {
                return `${key}=${params[key]}`
            })
            .join('&');
        const path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/summary/${type}/${statistic}?${queryString}`;
        console.log(path);
        return this.get(path, params);
    },

    getDriverStatistics(username) {
        const path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/summary/drivers/${username}/statistics`;
        return this.get(path);
    },

    getDriverCompletedOrdersCount(username, params = {}) {
        const queryString = Object.keys(params)
            .map((key) => {
                return `${key}=${params[key]}`
            })
            .join('&');
        const path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/summary/drivers/${username}/completedTransportationOrdersCount?${queryString}`;
        return this.get(path, params);
    },

    preferredTransporterRulesFindAll() {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/preferred-transporter-rules`;
        return this.get(path);
    },
    preferredTransporterRulesCreate(body) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/preferred-transporter-rules`;
        return this.post(path, body);
    },
    preferredTransporterRulesGetById(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/preferred-transporter-rules/${id}`;
        return this.get(path);
    },
    preferredTransporterRulesUpdateById(id, body) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/preferred-transporter-rules/${id}`;
        return this.put(path, body);
    },
    preferredTransporterRulesDeleteById(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/preferred-transporter-rules/${id}`;
        return this.delete(path);
    },
    getDriverDetails(query) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/driver-details`;
        const queryString = Object.keys(query)
            .map((key) => {
                return `${key}=${query[key]}`
            })
            .join('&');

        path = `${path}?${queryString}`;

        return this.get(path);
    },
    getTransportationOrderEtas(id, type = 'pickup') {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${id}/eta/selection/${type}`
        return this.get(path);
    },

    postTableDataExport(data, timezone = undefined) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/table-data-export/`
        return this.post(path, data, timezone);
    },

    postOrdersBatchOperation(type, operation, data) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/batches/${type}/${operation}`
        return this.post(path, data);
    },

    getBatchOperationSummary(id) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/batches/${id}/summary`
        return this.get(path);
    },

    getBatchOperations(query="") {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/batches?${query}`
        return this.get(path);
    },

    getBatchOperationItems(query) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/batch-items?query=${query}`
        return this.get(path);
    },

    get(path) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }

        if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
            headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
            headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
        }

        if (TokenService.hasSpecifiedRegistrationIds() && TokenService.getIntercompaniesCount() > TokenService.getSpecifiedRegistrationIds().length) {
            headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
        }

        const span = BugsnagPerformance.startSpan(path);
        const cancellationSource = CancelToken.source();
        const requestId = this._requestId++;
        this.cancellationSources[requestId] = {
            path: path,
            cancellationSource
        };

        return Client.axiosClient.get(path, {
            headers,
            params,
            cancelToken: cancellationSource.token
        })
            .finally(() => {
                span.end();
                delete this.cancellationSources[requestId];
            });
    },
    post(path, data, timezone = undefined) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }
        headers['Request-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;;
        if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
            headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
            headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
        }

        if (TokenService.hasSpecifiedRegistrationIds() && TokenService.getIntercompaniesCount() > TokenService.getSpecifiedRegistrationIds().length) {
            headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
        }

        const span = BugsnagPerformance.startSpan(path);
        const cancellationSource = CancelToken.source();
        const requestId = this._requestId++;
        this.cancellationSources[requestId] = {
            path: path,
            cancellationSource,
            cancelToken: cancellationSource.token
        };

        return Client.axiosClient.post(path, data, {
            headers,
            params,
            cancelToken: cancellationSource.token
        })
            .finally(() => {
                span.end();
                delete this.cancellationSources[requestId];
            });
    },
    put(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};
        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }

        if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
            headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
            headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
        }

        if (TokenService.hasSpecifiedRegistrationIds() && TokenService.getIntercompaniesCount() > TokenService.getSpecifiedRegistrationIds().length) {
            headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
        }

        const span = BugsnagPerformance.startSpan(path);
        const cancellationSource = CancelToken.source();
        const requestId = this._requestId++;
        this.cancellationSources[requestId] = {
            path: path,
            cancellationSource,
            cancelToken: cancellationSource.token
        };

        return Client.axiosClient.put(path, data, {
            headers,
            params,
            cancelToken: cancellationSource.token
        })
            .finally(() => {
                span.end();
                delete this.cancellationSources[requestId];
            });
    },
    patch(path, data) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};

        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }

        if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
            headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
            headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
        }

        if (TokenService.hasSpecifiedRegistrationIds() && TokenService.getIntercompaniesCount() > TokenService.getSpecifiedRegistrationIds().length) {
            headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
        }

        const span = BugsnagPerformance.startSpan(path);
        const cancellationSource = CancelToken.source();
        const requestId = this._requestId++;
        this.cancellationSources[requestId] = {
            path: path,
            cancellationSource,
            cancelToken: cancellationSource.token
        };

        return Client.axiosClient.patch(path, data, {
            headers,
            params,
            cancelToken: cancellationSource.token
        })
            .finally(() => {
                span.end();
                delete this.cancellationSources[requestId];
            });
    },
    delete(path) {
        let token = TokenService.hasImpersonationToken() ? TokenService.getImpersonationToken() : TokenService.getToken();
        let params = {};

        if (TokenService.hasImpersonatedByUsername()) {
            params['byAdmin'] = TokenService.getImpersonatedByUsername();
        }

        let headers = {
            'Authorization': `Bearer ${token}`
        }

        if (TokenService.hasSpecifiedRegistrationId() && TokenService.hasIntercompanyName()) {
            headers['X-Intercompany-ID'] = TokenService.getSpecifiedRegistrationId();
            headers['X-Intercompany-Name'] = TokenService.getIntercompanyName();
        }

        if (TokenService.hasSpecifiedRegistrationIds() && TokenService.getIntercompaniesCount() > TokenService.getSpecifiedRegistrationIds().length) {
            headers['X-Intercompany-Read-IDs'] = TokenService.getSpecifiedRegistrationIds().join(',');
        }

        const span = BugsnagPerformance.startSpan(path);
        const cancellationSource = CancelToken.source();
        const requestId = this._requestId++;
        this.cancellationSources[requestId] = {
            path: path,
            cancellationSource,
            cancelToken: cancellationSource.token
        };

        return Client.axiosClient.delete(path, {
            headers,
            params,
            cancelToken: cancellationSource.token
        })
            .finally(() => {
                span.end();
                delete this.cancellationSources[requestId];
            });
    },

    getFilterValuesFromServer(data, type) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/search/distinct/${type}`;
        return this.post(path, data);
    },

    getLoadBoards() {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/load-boards`;
        return this.get(path);
    },

    getCancelLoadBoard(orderId) {
        let path = `${_get(window, 'CONFIG.runbuggy.ordersWorkflowApi.path')}/transportation-orders/${encodeURIComponent(orderId)}/cancelLoadBoard`;
        return this.get(path);
    },

};

export default Client;
