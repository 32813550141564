import Vue from "vue";
import Vuex from "vuex";

import app from "./modules/app";
import listItem2MapMarker from "./modules/bind--list-item--map-marker";
import googleMaps from "./modules/googleMaps";
import perspective from "./modules/perspective";
import taskList from "./modules/taskList";
import ui from "./modules/ui";
import tasks from "./modules/tasks";
import orderDetails from "./modules/orderDetails";
import user from "./modules/user";
import workflows from "./modules/workflows";
import featureExplanation from "./modules/feature-explanation";
import customFields from "./modules/customFields";

Vue.use(Vuex);

const getters = {};

const actions = {};

export default new Vuex.Store({
  actions: actions,
  getters: getters,
  modules: {
    app,
    listItem2MapMarker,
    googleMaps,
    perspective,
    taskList,
    ui,
    tasks,
    user,
    orderDetails,
    workflows,
    featureExplanation,
    customFields,
  },
});
