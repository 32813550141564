import Vue from "vue";
import Router from "vue-router";
import GoogleAnalyticsService from "@/services/googleAnalytics";

import RouterView from "@/components/router-view";
import BugsnagService from "SC/services/bugsnag";
import TokenService from "SC/services/tokenService";
import LAYOUTS from "@/constants/layouts";
import OktaService from "@/services/okta";
import MobileAppService from "@/services/mobileApp";

import Roles from "SC/constants/roles";
import Middleware from "@/constants/middleware";
import store from "@/store";

import _get from "lodash/get";
import _each from "lodash/each";
import _omit from "lodash/omit";

import { log } from "./plugins/logger.js";
import LocalStorageService from "SC/services/localStorage";
import LocalStorageConstants from "@/constants/localstorage";

const LoginIndex = () => import("@/views/login");
const LoginWithToken = () => import("@/views/login/with-token");
const LoginErrorPage = () => import("@/views/login/error-page");
const LogoutIndex = () => import("@/views/logout");
const EnsureTenantRedirect = () =>
  import("@/views/login/ensure-tenant-redirect.vue");

const DashboardIndex = () => import("@/views/dashboard");
const HitchDashboardIndex = () => import("@/views/dashboard/hitch");
const MessagesIndex = () => import("@/views/messages");
const MonoProjectIndex = () => import("@/views/mono-project");
const MonoProjectWCIndex = () => import("@/views/mono-project/wc");

const TransporterDriverLocationMap = () =>
  import("@/views/transporter/driver-location-map");

const AdminDriverLocationMap = () =>
  import("@/views/admin/driver-location-map");

const AdminWorkflowsEdit = () => import("@/views/admin/workflows/edit");
const AdminWorkflowsDocumentation = () =>
  import("@/views/admin/workflows/documentation");
const AdminTaskListView = () => import("@/views/admin/task-lists/view");
const AdminOrderComparisonIndex = () =>
  import("@/views/admin/order-comparison");
const AdminOrdersViewEdit = () => import("@/views/admin/orders/view-edit");
const TransporterAccessRules = () =>
  import("@/views/admin/transporter-access-rules");
const AdminOrdersInvoicing = () =>
  import("@/views/orders/views/admin/views/invoicing");

const AdminOrdersVins = () => import("@/views/orders/views/admin/views/vins");

const NotificationTemplateDefinitionsIndex = () =>
  import("@/views/notifications/views/notification-template-definitions");

const NotificationTemplatesIndex = () =>
  import("@/views/notifications/views/notification-templates");

const WorkflowsIndex = () => import("@/views/workflows");
const WorkflowsStartIndex = () => import("@/views/workflows/views/start");
const WorkflowsStepIndex = () => import("@/views/workflows/views/step");
const WorkflowsMessageIndex = () => import("@/views/workflows/views/message");

const OrdersCreateIndex = () => import("@/views/orders/create");
const OrdersListTransporter = () => import("@/views/orders/list/transporter");
const OrdersListShipper = () => import("@/views/orders/list/shipper");
const OrdersListShipperVins = () => import("@/views/orders/list/shipper-vins");

const OrdersCompletenessManagerIndex = () =>
  import("@/views/orders/completeness");

const ReportsPayoutsIndex = () => import("@/views/reports/payouts");
const ReportsPaymentsIndex = () => import("@/views/reports/payments");
const ReportsChargesIndex = () => import("@/views/reports/charges");
const ReportsChargesDetails = () => import("@/views/reports/charges/details");
const ReportsInvoicesIndex = () => import("@/views/reports/invoices");

const OrdersViewIndex = () => import("@/views/orders/view");
const OrdersCreatePickup = () => import("@/views/orders/create/pickup");
const OrdersCreateBulk = () => import("@/views/orders/create/bulk");

const TaskManagementIndex = () => import("@/views/tasks");

const VinManagementIndex = () => import("@/views/vins");

const RationalesIndex = () => import("@/views/admin/rationeles");
const GenericTasksDefinitionsIndex = () =>
  import("@/views/admin/generic-tasks-definitions");
const PreferredDedicatedRules = () =>
  import("@/views/admin/preferred-dedicated-rules");
const DMNViewIndex = () => import("@/views/admin/dmn-view");

const UserForgotPasswordChange = () =>
  import("@/views/user/forgot-password-change");
const UserProfileSettings = () => import("@/views/user/profile-settings");

const UserAccountManagementProfile = () =>
  import("@/views/user/account-management/profile");
const UserAccountManagementCompany = () =>
  import("@/views/user/account-management/company");
const UserAccountManagementSubcompanies = () =>
  import("@/views/user/account-management/sub-companies");
const UserAccountManagementCompanies = () =>
  import("@/views/user/account-management/companies");
const UserAccountManagementSubContractors = () =>
  import("@/views/user/account-management/sub-contractors");
const UserAccountManagementThemeSetup = () =>
  import("@/views/user/account-management/theme-setup");
const UserAccountManagementPayments = () =>
  import("@/views/user/account-management/payments");
const UserAccountManagementDocuments = () =>
  import("@/views/user/account-management/documents");
const UserAccountManagementTransporters = () =>
  import("@/views/user/account-management/transporters");
const UserAccountManagementDrivers = () =>
  import("@/views/user/account-management/drivers");
const UserAccountManagementAuthorizedCompany = () =>
  import("@/views/user/account-management/authorized-company");
const UserAccountManagementAuthorizedAuctions = () =>
  import("@/views/user/account-management/authorized-auctions");
const UserAccountManagementAuthorizedDealers = () =>
  import("@/views/user/account-management/authorized-dealers");
const UserAccountManagementUsers = () =>
  import("@/views/user/account-management/users");
const UserAccountManagementLocations = () =>
  import("@/views/user/account-management/locations");
const UserAccountManagementNotifications = () =>
  import("@/views/user/account-management/notifications");
const UserAccountManagement = () => import("@/views/user/account-management");
const UserSocketMessaging = () => import("@/views/user/socket-messaging");

const UserTransporterOnboarding = () =>
  import("@/views/user/transporter-onboarding");

const UserManagement = () => import("@/views/admin/user-management");
const DocumentManagement = () => import("@/views/admin/document-management");
const CompanyManagement = () => import("@/views/admin/company-management");

const OrdersAdminOrders = () => import("@/views/orders/views/admin");
const Test = () => import("@/views/test");

const ExternalOrderStatus = () => import("@/views/external/order-status");
const ExternalPreOrder = () => import("@/views/external/pre-order");

const LayoutAuthenticatedMaterial = () => import("@/layouts/authenticated");

let orderDetailsPerspectiveRoles;

// Log router errors
const _routerPushFunction = Router.prototype.push;
Router.prototype.push = function push(location) {
  return _routerPushFunction
    .call(this, location)
    .catch((err) => log("Router push", err, "debug"));
};

const _routerReplaceFunction = Router.prototype.replace;
Router.prototype.replace = function push(location) {
  return _routerReplaceFunction
    .call(this, location)
    .catch((err) => log("Router replace", err, "debug"));
};

Vue.use(Router);

const router = new Router({
  mode: "hash",
  hash: true,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/external",
      component: RouterView,
      meta: {
        layout: LAYOUTS.BLANK,
      },
      children: [
        {
          path: "order-status",
          name: "external_order-status",
          component: ExternalOrderStatus,
          meta: {},
        },
        {
          path: "pre-order",
          name: "external_pre-order",
          component: ExternalPreOrder,
          meta: {},
        },
      ],
    },

    {
      path: "/test",
      name: "test",
      component: Test,
      meta: {},
    },

    {
      path: "/dashboard",
      name: "dashboard",
      component: DashboardIndex,
      meta: {
        requiresAuth: true,
        layout: LAYOUTS.AUTHENTICATED,
        middleware: {
          ROLES: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
            Roles.TMS_ADMIN,
            Roles.TMS_ADMIN_TRIAL,
            Roles.TMS_USER,
            Roles.TMS_USER_TRIAL,
            Roles.AUCTION_ADMIN,
            Roles.AUCTION_USER,
            Roles.AUCTION_ADMIN_TRIAL,
            Roles.AUCTION_USER_TRIAL,
            Roles.TRANSPORTER_ADMIN,
            Roles.TRANSPORTER_USER,
            Roles.TRANSPORTER_DRIVER,
            Roles.TRANSPORTER_ADMIN_TRIAL,
            Roles.TRANSPORTER_USER_TRIAL,
            Roles.TRANSPORTER_DRIVER_TRIAL,
            Roles.SHIPPER_ADMIN,
            Roles.SHIPPER_USER,
            Roles.SHIPPER_MANAGER,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
            Roles.SHIPPER_ADMIN_TRIAL,
            Roles.SHIPPER_USER_TRIAL,
            Roles.SHIPPER_MANAGER_TRIAL,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER_TRIAL,
            Roles.PRIVATE_SHIPPER_ADMIN,
            Roles.PRIVATE_SHIPPER_USER,
            Roles.PRIVATE_SHIPPER_ADMIN_TRIAL,
            Roles.PRIVATE_SHIPPER_USER_TRIAL,
          ],
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
    },
    {
      path: "/hitch-dashboard",
      name: "hitch-dashboard",
      component: HitchDashboardIndex,
      meta: {
        requiresAuth: true,
        layout: LAYOUTS.AUTHENTICATED,
        middleware: {
          ROLES: [Roles.RUNBUGGY_SUPER_ADMIN],
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
    },
    {
      path: "/messages",
      name: "messages",
      component: MessagesIndex,
      meta: {
        requiresAuth: true,
        layout: LAYOUTS.AUTHENTICATED,
        middleware: {
          ROLES: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
            Roles.TMS_ADMIN,
            Roles.TMS_ADMIN_TRIAL,
            Roles.TMS_USER,
            Roles.TMS_USER_TRIAL,
            Roles.AUCTION_ADMIN,
            Roles.AUCTION_USER,
            Roles.AUCTION_ADMIN_TRIAL,
            Roles.AUCTION_USER_TRIAL,
            Roles.TRANSPORTER_ADMIN,
            Roles.TRANSPORTER_USER,
            Roles.TRANSPORTER_DRIVER,
            Roles.TRANSPORTER_ADMIN_TRIAL,
            Roles.TRANSPORTER_USER_TRIAL,
            Roles.TRANSPORTER_DRIVER_TRIAL,
            Roles.SHIPPER_ADMIN,
            Roles.SHIPPER_USER,
            Roles.SHIPPER_MANAGER,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
            Roles.SHIPPER_ADMIN_TRIAL,
            Roles.SHIPPER_USER_TRIAL,
            Roles.SHIPPER_MANAGER_TRIAL,
            Roles.SHIPPER_BUSINESS_UNIT_MANAGER_TRIAL,
            Roles.PRIVATE_SHIPPER_ADMIN,
            Roles.PRIVATE_SHIPPER_USER,
            Roles.PRIVATE_SHIPPER_ADMIN_TRIAL,
            Roles.PRIVATE_SHIPPER_USER_TRIAL,
          ],
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
      children: [
        {
          path: "",
          name: "messages-index",
          ccomponent: MessagesIndex,
        },
        {
          path: "*",
          name: "messages-index",
          ccomponent: MessagesIndex,
        },
      ],
    },
    {
      path: "/pricing-ui/*",
      name: "mono-project-pricing-ui",
      component: MonoProjectIndex,
      meta: {
        middleware: {
          ROLES: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.PRICE_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
          ],
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
    },
    {
      path: "/integration-ui/*",
      name: "mono-project-integration-ui",
      component: MonoProjectIndex,
      meta: {
        middleware: {
          ROLES: [Roles.RUNBUGGY_SUPER_ADMIN, Roles.RUNBUGGY_ADMIN],
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
    },
    {
      path: "/tms-integration-ui/*",
      name: "mono-project-integration-ui",
      component: MonoProjectWCIndex,
      meta: {
        middleware: {
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
    },
    {
      path: "/admin",
      component: RouterView,
      meta: {
        middleware: {
          ROLES: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
          ],
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
      children: [
        {
          path: "user-management",
          name: "user-management",
          meta: {
            layout: LAYOUTS.AUTHENTICATED,
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
              ],
            },
          },
          component: UserManagement,
        },
        {
          path: "document-management",
          name: "document-management",
          meta: {
            layout: LAYOUTS.AUTHENTICATED,
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
              ],
            },
          },
          component: DocumentManagement,
        },
        {
          path: "company-management",
          name: "company-management",
          meta: {
            layout: LAYOUTS.AUTHENTICATED,
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
              ],
            },
          },
          component: CompanyManagement,
        },
      ],
    },
    {
      path: "/user",
      component: RouterView,
      meta: {
        middleware: {
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
      children: [
        {
          path: "forgot-password-change",
          name: "user-forgot_password_change",
          meta: {
            layout: LAYOUTS.LOGIN,
            middleware: {
              [Middleware.MUST_HAVE_TEMPORARY_PASSWORD]: true,
            },
          },
          component: UserForgotPasswordChange,
        },
        {
          path: "profile-settings",
          name: "user-profile_settings",
          meta: {
            layout: LAYOUTS.AUTHENTICATED,
          },
          component: UserProfileSettings,
        },
        {
          path: "user-management",
          name: "user-management",
          meta: {
            layout: LAYOUTS.AUTHENTICATED,
            ROLES: [
              Roles.RUNBUGGY_SUPER_ADMIN,
              Roles.RUNBUGGY_ADMIN,
              Roles.RUNBUGGY_OPERATIONS,
            ],
          },
          component: UserManagement,
        },
        {
          path: "socket-messaging",
          name: "user-socket-messaging",
          meta: {
            layout: LAYOUTS.AUTHENTICATED,
          },
          component: UserSocketMessaging,
        },
        {
          path: "onboarding",
          name: "user-transporter-onboarding",
          component: UserTransporterOnboarding,
          meta: {
            middleware: {
              ROLES: [
                Roles.TRANSPORTER,
                Roles.TRANSPORTER_ADMIN,
                Roles.TRANSPORTER_USER,
                Roles.TRANSPORTER_ADMIN_TRIAL,
                Roles.TRANSPORTER_USER_TRIAL,
              ],
            },
          },
        },
        {
          path: "account-management",
          name: "user-account-management",
          meta: {
            layout: LAYOUTS.AUTHENTICATED,
          },
          component: UserAccountManagement,
          children: [
            {
              path: "profile",
              name: "user-account-management-profile",
              component: UserAccountManagementProfile,
              props: true,
              meta: {},
            },
            {
              path: "company",
              name: "user-account-management-company",
              component: UserAccountManagementCompany,
              meta: {
                middleware: {
                  ROLES: [
                    Roles.RUNBUGGY_SUPER_ADMIN,
                    Roles.RUNBUGGY_ADMIN,
                    Roles.RUNBUGGY_OPERATIONS,
                    Roles.TRANSPORTER_ADMIN,
                    Roles.TRANSPORTER_ADMIN_TRIAL,
                    Roles.TRANSPORTER_USER,
                    Roles.SHIPPER_ADMIN,
                    Roles.SHIPPER_ADMIN_TRIAL,
                    Roles.SHIPPER_USER,
                    Roles.AUCTION_ADMIN,
                    Roles.AUCTION_ADMIN_TRIAL,
                    Roles.AUCTION_USER,
                    Roles.TMS_ADMIN,
                    Roles.TMS_ADMIN_TRIAL,
                  ],
                },
              },
            },
            {
              path: "companies/:companyId?/:tab?",
              name: "user-account-management-companies",
              component: UserAccountManagementCompanies,
              props: true,
              meta: {
                middleware: {
                  ROLES: [
                    Roles.RUNBUGGY_SUPER_ADMIN,
                    Roles.RUNBUGGY_ADMIN,
                    Roles.RUNBUGGY_OPERATIONS,
                    Roles.TMS_ADMIN,
                  ],
                },
              },
            },
            {
              path: "subcontractors",
              name: "user-account-management-sub-contractors",
              component: UserAccountManagementSubContractors,
              props: true,
              meta: {
                middleware: {
                  ROLES: [Roles.TRANSPORTER_BROKER],
                },
              },
            },
            {
              path: "subcompanies",
              name: "user-account-management-subcompanies",
              component: UserAccountManagementSubcompanies,
              props: true,
              meta: {
                middleware: {
                  ROLES: [Roles.SHIPPER_ADMIN, Roles.SHIPPER_ADMIN_TRIAL],
                },
              },
            },
            {
              path: "theme-setup",
              name: "user-account-management-theme_setup",
              component: UserAccountManagementThemeSetup,
              props: true,
            },
            {
              path: "payments",
              name: "user-account-management-payments",
              component: UserAccountManagementPayments,
              meta: {
                middleware: {
                  ROLES: [
                    Roles.TRANSPORTER_ADMIN,
                    Roles.TRANSPORTER_ADMIN_TRIAL,
                    Roles.SHIPPER_ADMIN,
                    Roles.SHIPPER_ADMIN_TRIAL,
                    Roles.PRIVATE_SHIPPER_ADMIN,
                    Roles.PRIVATE_SHIPPER_ADMIN_TRIAL,
                    Roles.AUCTION_ADMIN,
                    Roles.AUCTION_ADMIN_TRIAL,
                    Roles.TMS_ADMIN,
                    Roles.TMS_ADMIN_TRIAL,
                  ],
                },
              },
            },
            {
              path: "documents",
              name: "user-account-management-documents",
              component: UserAccountManagementDocuments,
              meta: {
                middleware: {
                  ROLES: [
                    Roles.TRANSPORTER_ADMIN,
                    Roles.TRANSPORTER_ADMIN_TRIAL,
                  ],
                },
              },
            },
            {
              path: "transporters",
              name: "user-account-management-transporters",
              component: UserAccountManagementTransporters,
              meta: {
                middleware: {
                  ROLES: [
                    Roles.SHIPPER_ADMIN,
                    Roles.SHIPPER_ADMIN_TRIAL,
                    Roles.SHIPPER_USER,
                    Roles.SHIPPER_USER_TRIAL,
                    Roles.AUCTION_ADMIN,
                    Roles.AUCTION_ADMIN_TRIAL,
                    Roles.AUCTION_USER,
                    Roles.AUCTION_USER_TRIAL,
                  ],
                },
              },
            },
            {
              path: "drivers",
              name: "user-account-management-drivers",
              component: UserAccountManagementDrivers,
              meta: {
                middleware: {
                  ROLES: [
                    Roles.TRANSPORTER,
                    Roles.TRANSPORTER_ADMIN,
                    Roles.TRANSPORTER_USER,
                    Roles.TRANSPORTER_ADMIN_TRIAL,
                    Roles.TRANSPORTER_USER_TRIAL,
                  ],
                },
              },
            },
            {
              path: "authorized-company",
              name: "user-account-management-authorized_company",
              component: UserAccountManagementAuthorizedCompany,
              meta: {
                middleware: {
                  ROLES: [
                    Roles.SHIPPER_ADMIN,
                    Roles.SHIPPER_ADMIN_TRIAL,
                    Roles.SHIPPER_USER,
                    Roles.SHIPPER_USER_TRIAL,
                    Roles.AUCTION_ADMIN,
                    Roles.AUCTION_ADMIN_TRIAL,
                    Roles.AUCTION_USER,
                    Roles.AUCTION_USER_TRIAL,
                    Roles.TMS_ADMIN,
                    Roles.TMS_ADMIN_TRIAL,
                  ],
                },
              },
            },
            {
              path: "authorized-auctions",
              name: "user-account-management-authorized_auctions",
              component: UserAccountManagementAuthorizedAuctions,
              meta: {
                middleware: {
                  ROLES: [
                    Roles.SHIPPER_ADMIN,
                    Roles.SHIPPER_ADMIN_TRIAL,
                    Roles.SHIPPER_USER,
                    Roles.SHIPPER_USER_TRIAL,
                  ],
                },
              },
            },
            {
              path: "authorized-dealers",
              name: "user-account-management-authorized_dealers",
              component: UserAccountManagementAuthorizedDealers,
              meta: {
                middleware: {
                  ROLES: [
                    Roles.AUCTION_ADMIN,
                    Roles.AUCTION_ADMIN_TRIAL,
                    Roles.AUCTION_USER,
                    Roles.AUCTION_USER_TRIAL,
                  ],
                },
              },
            },
            {
              path: "users",
              name: "user-account-management-users",
              component: UserAccountManagementUsers,
              meta: {
                middleware: {
                  ROLES: [
                    Roles.RUNBUGGY_SUPER_ADMIN,
                    Roles.RUNBUGGY_ADMIN,
                    Roles.RUNBUGGY_OPERATIONS,
                    Roles.TRANSPORTER_ADMIN,
                    Roles.TRANSPORTER_ADMIN_TRIAL,
                    Roles.SHIPPER_ADMIN,
                    Roles.SHIPPER_ADMIN_TRIAL,
                    Roles.PRIVATE_SHIPPER_ADMIN,
                    Roles.PRIVATE_SHIPPER_ADMIN_TRIAL,
                    Roles.AUCTION_ADMIN,
                    Roles.AUCTION_ADMIN_TRIAL,
                    Roles.TMS_ADMIN,
                    Roles.TMS_ADMIN_TRIAL,
                  ],
                },
              },
            },
            {
              path: "locations",
              name: "user-account-management-locations",
              component: UserAccountManagementLocations,
              meta: {
                middleware: {
                  ROLES: [
                    Roles.SHIPPER_ADMIN,
                    Roles.AUCTION_ADMIN,
                    Roles.TMS_ADMIN,
                  ],
                },
              },
            },
            {
              path: "notifications",
              name: "user-account-management-notifications",
              component: UserAccountManagementNotifications,
              meta: {},
            },
          ],
        },
      ],
    },
    {
      path: "/reports",
      component: RouterView,
      meta: {
        middleware: {
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
      children: [
        {
          path: "payouts",
          name: "reports-payouts",
          component: ReportsPayoutsIndex,
          meta: {
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.TRANSPORTER_ADMIN,
                Roles.TRANSPORTER_USER,
                Roles.TMS_ADMIN,
              ],
            },
          },
        },
        {
          path: "payments",
          name: "reports-payments",
          component: ReportsPaymentsIndex,
          meta: {
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.TRANSPORTER_ADMIN,
                Roles.TRANSPORTER_USER,
                Roles.TMS_ADMIN,
              ],
            },
          },
        },
        {
          path: "charges",
          name: "reports-charges",
          component: ReportsChargesIndex,
          meta: {
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.SHIPPER_ADMIN,
                Roles.SHIPPER_USER,
                Roles.SHIPPER_MANAGER,
                Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
                Roles.AUCTION_ADMIN,
                Roles.AUCTION_USER,
              ],
              [Middleware.ROLES_CONTEXT]: ["RUNBUGGY"],
            },
          },
        },
        {
          path: "invoices",
          name: "reports-invoices",
          component: ReportsInvoicesIndex,
          meta: {
            middleware: {
              ROLES: [Roles.TMS_ADMIN],
            },
          },
        },
        {
          path: "charge/:id",
          name: "reports-charges-details",
          component: ReportsChargesDetails,
          meta: {
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.PRIVATE_SHIPPER_ADMIN,
                Roles.SHIPPER_ADMIN,
                Roles.SHIPPER_USER,
                Roles.SHIPPER_MANAGER,
                Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
                Roles.AUCTION_ADMIN,
                Roles.AUCTION_USER,
              ],
              [Middleware.ROLES_CONTEXT]: ["RUNBUGGY"],
            },
          },
        },
      ],
    },
    {
      path: "/orders",
      component: RouterView,
      meta: {
        requiresAuth: true,
        middleware: {
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
      children: [
        {
          path: "create",
          name: "orders-create",
          component: OrdersCreateIndex,
          meta: {
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.PRIVATE_SHIPPER_ADMIN,
                Roles.PRIVATE_SHIPPER_USER,
                Roles.SHIPPER_ADMIN,
                Roles.SHIPPER_USER,
                Roles.AUCTION_ADMIN,
                Roles.AUCTION_USER,
                Roles.TMS_ADMIN,
                Roles.SHIPPER,
              ],
            },
          },
          children: [
            {
              path: "pickup",
              name: "orders-create-pickup",
              component: OrdersCreatePickup,
            },
          ],
        },
        {
          path: "create-bulk",
          name: "orders-create-bulk",
          component: OrdersCreateBulk,
          meta: {
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.PRIVATE_SHIPPER_ADMIN,
                Roles.PRIVATE_SHIPPER_USER,
                Roles.SHIPPER_ADMIN,
                Roles.SHIPPER_USER,
                Roles.AUCTION_ADMIN,
                Roles.AUCTION_USER,
                Roles.TMS_ADMIN,
                Roles.TMS_USER,
                Roles.SHIPPER,
              ],
            },
          },
        },
        {
          path: "modify/:orderId",
          name: "orders-modify",
          component: OrdersCreateIndex,
          meta: {
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.PRIVATE_SHIPPER_ADMIN,
                Roles.PRIVATE_SHIPPER_USER,
                Roles.SHIPPER_ADMIN,
                Roles.SHIPPER_USER,
                Roles.AUCTION_ADMIN,
                Roles.AUCTION_USER,
                Roles.TMS_ADMIN,
                Roles.TMS_USER,
                Roles.SHIPPER,
                Roles.TRANSPORTER_ADMIN,
                Roles.TRANSPORTER_USER,
              ],
            },
          },
        },
        {
          path: "completeness-manager/:orderId",
          name: "order-completeness-manager",
          component: OrdersCompletenessManagerIndex,
          meta: {
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.PRIVATE_SHIPPER_ADMIN,
                Roles.PRIVATE_SHIPPER_USER,
                Roles.SHIPPER_ADMIN,
                Roles.SHIPPER_USER,
                Roles.AUCTION_ADMIN,
                Roles.AUCTION_USER,
                Roles.TMS_ADMIN,
              ],
            },
          },
        },
        {
          path: "change-request/:orderId/:changeRequestTaskId",
          name: "order-change-request",
          component: OrdersCreateIndex,
          meta: {
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.PRIVATE_SHIPPER_ADMIN,
                Roles.PRIVATE_SHIPPER_USER,
                Roles.SHIPPER_ADMIN,
                Roles.SHIPPER_USER,
                Roles.AUCTION_ADMIN,
                Roles.AUCTION_USER,
              ],
            },
          },
        },
        {
          path: "list/transporter",
          meta: {
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.TRANSPORTER_ADMIN,
                Roles.TRANSPORTER_USER,
                Roles.TRANSPORTER_DRIVER,
                Roles.TRANSPORTER_ADMIN_TRIAL,
                Roles.TRANSPORTER_USER_TRIAL,
                Roles.TRANSPORTER_DRIVER_TRIAL,
                Roles.TMS_ADMIN,
                Roles.TMS_USER,
                Roles.TRANSPORTER,
                Roles.TO_VIEW,
              ],
            },
          },
          name: "orders-list-transporter",
          component: OrdersListTransporter,
        },
        {
          path: "list/transporter/vins/:orderId?",
          meta: {
            middleware: {
              ROLES: [Roles.TRANSPORTER_BROKER],
            },
          },
          name: "orders-list-transporter-vins",
          component: VinManagementIndex,
        },
        {
          path: "list/shipper",
          meta: {
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.RUNBUGGY_ADMIN,
                Roles.RUNBUGGY_OPERATIONS,
                Roles.PRIVATE_SHIPPER_ADMIN,
                Roles.PRIVATE_SHIPPER_USER,
                Roles.PRIVATE_SHIPPER_ADMIN_TRIAL,
                Roles.PRIVATE_SHIPPER_USER_TRIAL,
                Roles.SHIPPER_ADMIN,
                Roles.SHIPPER_USER,
                Roles.SHIPPER_MANAGER,
                Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
                Roles.SHIPPER_ADMIN_TRIAL,
                Roles.SHIPPER_USER_TRIAL,
                Roles.SHIPPER_MANAGER_TRIAL,
                Roles.SHIPPER_BUSINESS_UNIT_MANAGER_TRIAL,
                Roles.AUCTION_ADMIN,
                Roles.AUCTION_USER,
                Roles.AUCTION_ADMIN_TRIAL,
                Roles.AUCTION_USER_TRIAL,
                Roles.TMS_ADMIN,
                Roles.SHIPPER,
                Roles.SO_VIEW,
              ],
            },
          },
          name: "orders-list-shipper",
          component: OrdersListShipper,
        },
        {
          path: "list/vins:orderId?",
          meta: {
            middleware: {
              ROLES: [
                Roles.SHIPPER_ADMIN,
                Roles.SHIPPER_USER,
                Roles.SHIPPER_MANAGER,
                Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
                Roles.SHIPPER_ADMIN_TRIAL,
                Roles.SHIPPER_USER_TRIAL,
                Roles.SHIPPER_MANAGER_TRIAL,
                Roles.SHIPPER_BUSINESS_UNIT_MANAGER_TRIAL,
                Roles.AUCTION_ADMIN,
                Roles.AUCTION_USER,
                Roles.AUCTION_ADMIN_TRIAL,
                Roles.AUCTION_USER_TRIAL,
              ],
            },
          },
          name: "orders-list-shipper-vins",
          component: OrdersListShipperVins,
        },
        {
          path: ":perspective/:orderId",
          name: "orders-view",
          meta: {
            middleware: {
              ROLES: orderDetailsPerspectiveRoles,
            },
          },
          component: OrdersViewIndex,
        },
      ],
    },
    {
      path: "/notifications",
      component: RouterView,
      meta: {
        requiresAuth: true,
        middleware: {
          [Middleware.REQUIRES_AUTH]: true,
          ROLES: [Roles.RUNBUGGY_SUPER_ADMIN, Roles.NOTIFICATIONS_ADMIN],
          GROUPS: [Roles.NOTIFICATIONS_ADMIN],
        },
      },
      children: [
        {
          path: "notification-template-definitions",
          meta: {
            middleware: {
              ROLES: [Roles.RUNBUGGY_SUPER_ADMIN, Roles.NOTIFICATIONS_ADMIN],
              GROUPS: [Roles.NOTIFICATIONS_ADMIN],
            },
          },
          name: "notification-template-definitions",
          component: NotificationTemplateDefinitionsIndex,
        },
        {
          path: "notification-templates",
          meta: {
            middleware: {
              ROLES: [Roles.RUNBUGGY_SUPER_ADMIN, Roles.NOTIFICATIONS_ADMIN],
              GROUPS: [Roles.NOTIFICATIONS_ADMIN],
            },
          },
          name: "notification-templates",
          component: NotificationTemplatesIndex,
        },
      ],
    },
    {
      name: "onboarding-signup-process-redirect",
      path: "/workflows/SignupProcess",
      redirect: "/workflows/onboarding/SignupProcess",
      meta: {
        requiresAuth: true,
        middleware: {
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
    },
    {
      path: "/workflows/:endpoint/:processKey",
      meta: {
        layout: LAYOUTS.WORKFLOWS,
      },
      component: WorkflowsIndex,
      children: [
        {
          path: "",
          name: "workflows",
          component: WorkflowsStartIndex,
        },
        {
          path: ":processInstanceID",
          component: RouterView,
          children: [
            {
              path: "step/:taskKey?",
              name: "workflows-instance-step",
              component: WorkflowsStepIndex,
            },
            {
              path: "message",
              name: "workflows-instance-message",
              component: WorkflowsMessageIndex,
            },
          ],
        },
      ],
    },
    {
      path: "/transporter",
      component: RouterView,
      meta: {
        middleware: {
          ROLES: [Roles.TRANSPORTER, Roles.TMS_ADMIN, Roles.TO_VIEW],
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
      children: [
        {
          path: "driver-location-map/:transportationOrderId?",
          component: RouterView,
          children: [
            {
              path: "",
              name: "transporter-driver_location_map-view",
              component: TransporterDriverLocationMap,
            },
          ],
        },
      ],
    },
    {
      path: "/admin",
      component: RouterView,
      meta: {
        requiresAuth: true,
        middleware: {
          ROLES: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
            Roles.TMS_ADMIN,
          ],
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
      children: [
        {
          path: "driver-location-map/:transportationOrderId?",
          component: RouterView,
          children: [
            {
              path: "",
              name: "admin-driver_location_map-view",
              component: AdminDriverLocationMap,
            },
          ],
        },
        {
          path: "task-list",
          component: RouterView,
          children: [
            {
              path: ":processKey/:filterId?/:taskId?",
              name: "admin-task_list-view",
              component: AdminTaskListView,
            },
          ],
        },
        {
          path: "order-comparison",
          component: LayoutAuthenticatedMaterial,
          children: [
            {
              path: "",
              name: "admin-order_comparison-index",
              ccomponent: AdminOrderComparisonIndex,
              meta: {
                title: "Order Comparison V1/V2",
                hideAuthenticatedHeader: true,
              },
            },
          ],
        },
        {
          path: "workflows",
          component: RouterView,
          children: [
            {
              path: "edit",
              name: "admin-workflows-edit",
              component: AdminWorkflowsEdit,
            },
            {
              path: "documentation/:component?",
              name: "admin-workflows-documentation",
              component: AdminWorkflowsDocumentation,
            },
          ],
        },
        {
          path: "orders",
          component: RouterView,
          children: [
            {
              path: "view-edit",
              name: "admin/orders/view-edit",
              component: AdminOrdersViewEdit,
              meta: {
                title: "View Editor",
              },
            },
            {
              path: "invoicing",
              name: "admin/orders/invoicing",
              component: AdminOrdersInvoicing,
              meta: {
                title: "Internal Orders",
              },
            },
            {
              path: "vehicles/:orderId?",
              name: "admin/orders/vehicles",
              component: AdminOrdersVins,
            },
          ],
        },
        {
          path: "orders",
          component: RouterView,
          children: [
            {
              path: "list",
              name: "admin/orders/perspective/default",
              component: OrdersAdminOrders,
              meta: {
                title: "Internal Orders",
              },
            },
            {
              path: ":perspective/:orderId?",
              alias: ["orders/:perspective", "orders/:orderId?"],
              name: "admin/orders/perspective",
              component: OrdersAdminOrders,
              meta: {
                title: "Internal Orders",
              },
            },
          ],
        },
        {
          path: "rationales",
          name: "admin/rationales",
          component: RationalesIndex,
          meta: {
            title: "Rationales",
            middleware: {
              ROLES: [Roles.RUNBUGGY_SUPER_ADMIN],
            },
          },
        },
        {
          path: "preferred-dedicated-rules",
          name: "admin/preferred-dedicated-rules",
          component: PreferredDedicatedRules,
          meta: {
            title: "Preferred Dedicated Rules",
            middleware: {
              ROLES: [Roles.RUNBUGGY_SUPER_ADMIN, Roles.RUNBUGGY_ADMIN],
            },
          },
        },
        {
          path: "timers",
          name: "dd/Timers",
          component: DMNViewIndex,
          meta: {
            title: "Timers",
            middleware: {
              ROLES: [Roles.RUNBUGGY_SUPER_ADMIN],
            },
          },
        },
        {
          path: "optimization-rules",
          name: "dd/OptimizationRules",
          component: DMNViewIndex,
          meta: {
            title: "Optimization Rules",
            middleware: {
              ROLES: [Roles.RUNBUGGY_SUPER_ADMIN],
            },
          },
        },
        {
          path: "assignment-rules",
          name: "dd/AssignmentRules",
          component: DMNViewIndex,
          meta: {
            title: "Assignment Rules",
            middleware: {
              ROLES: [Roles.RUNBUGGY_SUPER_ADMIN, Roles.RUNBUGGY_ADMIN],
            },
          },
        },
        {
          path: "approval-rules",
          name: "drd/ApprovalDefinitions",
          component: DMNViewIndex,
          meta: {
            title: "Approval Rules",
            middleware: {
              ROLES: [Roles.RUNBUGGY_SUPER_ADMIN],
            },
          },
        },
        {
          path: "shipper-order-rules",
          name: "drd/ShipperOrderRules",
          component: DMNViewIndex,
          meta: {
            title: "Shipper Order Rules",
            middleware: {
              ROLES: [Roles.RUNBUGGY_SUPER_ADMIN, Roles.RUNBUGGY_ADMIN],
            },
          },
        },
        {
          path: "transporter-access-rules",
          name: "admin/TransporterAccessRules",
          component: TransporterAccessRules,
          meta: {
            title: "Transporter Access Rules",
            middleware: {
              GROUPS: [Roles.ACCESS_RULE_ADMIN],
            },
          },
        },
        {
          path: "generic-tasks-definitions",
          name: "admin/GenericTasksDefinitions",
          component: GenericTasksDefinitionsIndex,
          meta: {
            title: "Generic Task Definitions",
            middleware: {
              ROLES: [
                Roles.RUNBUGGY_SUPER_ADMIN,
                Roles.TMS_ADMIN,
                Roles.VIEW_CONFIG_ADMIN,
              ],
              GROUPS: [Roles.VIEW_CONFIG_ADMIN],
            },
          },
        },
      ],
    },
    {
      path: "/tasks",
      component: RouterView,
      meta: {
        middleware: {
          ROLES: [
            Roles.RUNBUGGY_SUPER_ADMIN,
            Roles.RUNBUGGY_ADMIN,
            Roles.RUNBUGGY_OPERATIONS,
            Roles.TMS_ADMIN,
            Roles.TMS_USER,
          ],
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
      children: [
        {
          path: "management",
          component: RouterView,
          children: [
            {
              path: ":id/:child_id/:business_key/:task_id",
              name: "task-list-view",
              component: TaskManagementIndex,
            },
          ],
        },
      ],
    },
    {
      path: "/vins",
      component: RouterView,
      meta: {
        middleware: {
          ROLES: [Roles.TMS_ADMIN],
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
      children: [
        {
          path: "management",
          component: RouterView,
          children: [
            {
              path: ":id/:vin_id",
              name: "vin-list-view",
              component: VinManagementIndex,
            },
          ],
        },
        {
          path: ":id/:vin_id/create",
          component: RouterView,
          name: "vto-orders-create",
          component: OrdersCreateIndex,
        },
      ],
    },
    {
      path: "/",
      component: RouterView,
      meta: {
        middleware: {
          [Middleware.GUEST_ONLY]: true,
        },
      },
      redirect: "/login",
    },
    {
      path: "/login",
      meta: {
        layout: LAYOUTS.LOGIN,
        middleware: {
          [Middleware.GUEST_ONLY]: true,
        },
      },
      name: "login/index",
      component: LoginIndex,
    },
    {
      path: "/login/with-token",
      meta: {
        layout: LAYOUTS.LOGIN,
        middleware: {},
      },
      name: "login/with-token",
      component: LoginWithToken,
    },

    {
      path: "/login/error",
      meta: {
        layout: LAYOUTS.LOGIN,
        middleware: {
          [Middleware.GUEST_ONLY]: true,
        },
      },
      name: "login/error-page",
      component: LoginErrorPage,
    },
    {
      path: "/logout",
      name: "logout/index",
      meta: {
        layout: LAYOUTS.BLANK,
        middleware: {
          [Middleware.REQUIRES_AUTH]: true,
          [Middleware.ALLOW_TEMPORARY_PASSWORD]: true,
        },
      },
      component: LogoutIndex,
    },
    {
      path: "/ensure-tenant-redirect/:tenantId",
      name: "ensure-tenant-redirect",
      component: EnsureTenantRedirect,
      meta: {
        layout: LAYOUTS.BLANK,
        middleware: {
          [Middleware.REQUIRES_AUTH]: true,
        },
      },
    },
  ],
});
const handleMiddleware = async (to, from, next) => {
  let matchedRoutes = to.matched;
  let getters = _get(store, "getters");
  let isAuthenticated = false;
  let roleContext = getters["user/roleContext"] || "RUNBUGGY";

  if (LocalStorageService.get(LocalStorageConstants.LIMITED_LIFETIME_TOKEN)) {
    isAuthenticated = true;
  } else {
    let authState;
    await OktaService.getAuthState().then((result) => {
      authState = result;
    });
    isAuthenticated = _get(authState, "isAuthenticated");
    if (!getters["user/userData"]) {
      const userInfo = _get(authState, "accessToken.claims.runbuggy_user");
      await store.dispatch("user/SetUserData", userInfo);
      getters = _get(store, "getters");
    }

    if (!isAuthenticated) {
      TokenService.clearAll();
    }
  }

  let nextRouteOverride;
  let allowTemporaryPasswords = false;
  const isTemporaryPasswordSet = getters["user/isTemporaryPasswordSet"];
  const isImpersonating = getters["user/isImpersonating"];
  const isDriverOnly = getters["user/isDriverOnly"];
  const hasTMSUserRole = getters["user/hasTMSUserRole"];
  const hasTmsRole = getters["user/hasTmsRole"];
  const companyEnableShipperFeature =
    getters["user/companyEnableShipperFeature"];

  // Drivers are only allowed to see account management
  if (isDriverOnly && !isImpersonating) {
    let allowedPaths = ["/user/account-management", "/logout"];

    if (
      allowedPaths.filter((allowedPath) => {
        return _get(to, "path").indexOf(allowedPath) > -1;
      }).length === 0
    ) {
      return next({
        name: "user-account-management-profile",
      });
    }
  }

  if (_get(to, "params.perspective") === "shipper") {
    orderDetailsPerspectiveRoles = [
      Roles.RUNBUGGY_SUPER_ADMIN,
      Roles.RUNBUGGY_ADMIN,
      Roles.RUNBUGGY_OPERATIONS,
      Roles.PRIVATE_SHIPPER_ADMIN,
      Roles.PRIVATE_SHIPPER_USER,
      Roles.PRIVATE_SHIPPER_ADMIN_TRIAL,
      Roles.PRIVATE_SHIPPER_USER_TRIAL,
      Roles.SHIPPER_ADMIN,
      Roles.SHIPPER_USER,
      Roles.SHIPPER_MANAGER,
      Roles.SHIPPER_BUSINESS_UNIT_MANAGER,
      Roles.SHIPPER_ADMIN_TRIAL,
      Roles.SHIPPER_USER_TRIAL,
      Roles.SHIPPER_MANAGER_TRIAL,
      Roles.SHIPPER_BUSINESS_UNIT_MANAGER_TRIAL,
      Roles.AUCTION_ADMIN,
      Roles.AUCTION_USER,
      Roles.AUCTION_ADMIN_TRIAL,
      Roles.AUCTION_USER_TRIAL,
      Roles.TMS_ADMIN,
    ];
  } else {
    orderDetailsPerspectiveRoles = [
      Roles.RUNBUGGY_SUPER_ADMIN,
      Roles.RUNBUGGY_ADMIN,
      Roles.RUNBUGGY_OPERATIONS,
      Roles.TRANSPORTER_ADMIN,
      Roles.TRANSPORTER_USER,
      Roles.TRANSPORTER_DRIVER,
      Roles.TRANSPORTER_ADMIN_TRIAL,
      Roles.TRANSPORTER_USER_TRIAL,
      Roles.TRANSPORTER_DRIVER_TRIAL,
      Roles.TMS_ADMIN,
      Roles.TMS_USER,
    ];
  }
  const userRoles = getters["user/userRoles"] || [];
  const userGroups = getters["user/userGroups"] || [];
  const allRoles = [...userRoles, ...userGroups];
  matchedRoutes
    .map((route) => {
      return route.meta.middleware;
    })
    .forEach((middlewares) => {
      if (nextRouteOverride) {
        return;
      }
      _each(middlewares, (value, key) => {
        switch (key) {
          case Middleware.REQUIRES_AUTH:
            if (!isAuthenticated) {
              nextRouteOverride = {
                name: "login/index",
                query: {
                  redirect: to.path,
                },
              };
            }
            break;
          case Middleware.GUEST_ONLY:
            if (isAuthenticated) {
              nextRouteOverride = {
                name: "dashboard",
              };
            }
            break;
          case Middleware.ROLES:
            if (!value) value = orderDetailsPerspectiveRoles || [];
            const matches = allRoles.some((item) => value.includes(item));
            let hideShipperViewForTms =
              hasTmsRole &&
              to.name === "orders-list-shipper" &&
              !companyEnableShipperFeature;
            if (!matches || hideShipperViewForTms) {
              if (hasTmsRole) {
                nextRouteOverride = {
                  name: "orders-list-transporter",
                };
              } else {
                nextRouteOverride = {
                  name: "dashboard",
                };
              }
            }
            break;
          case Middleware.GROUPS:
            if (!value) value = orderDetailsPerspectiveRoles || [];
            const groupMatches = userGroups.some((item) =>
              value.includes(item)
            );
            if (!groupMatches) {
              if (hasTMSUserRole) {
                nextRouteOverride = {
                  name: "orders-list-transporter",
                };
              } else {
                nextRouteOverride = {
                  name: "dashboard",
                };
              }
            }
            break;
          case Middleware.MUST_HAVE_TEMPORARY_PASSWORD:
            if (!isTemporaryPasswordSet) {
              nextRouteOverride = {
                name: "dashboard",
              };
            }
            allowTemporaryPasswords = true;
            break;
          case Middleware.ALLOW_TEMPORARY_PASSWORD:
            allowTemporaryPasswords = true;
            break;
          case Middleware.ROLES_CONTEXT:
            if (!value.includes(roleContext)) {
              nextRouteOverride = {
                name: "dashboard",
              };
            }
            break;
        }
      });
    });

  if (
    !nextRouteOverride &&
    !allowTemporaryPasswords &&
    isTemporaryPasswordSet &&
    !isImpersonating
  ) {
    nextRouteOverride = {
      name: "user-forgot_password_change",
    };
  }

  // Prevent infinite loop
  if (nextRouteOverride && to && nextRouteOverride.name === to.name) {
    return next({});
  }

  next(nextRouteOverride ? nextRouteOverride : {});
};

router.beforeEach(handleMiddleware);

router.afterEach((to, from) => {
  GoogleAnalyticsService.recordPageView(to.fullPath);
  BugsnagService.setPath(to.fullPath);

  MobileAppService.sendMessage("ROUTE_CHANGE", _omit(to, ["matched"]));
});

export default router;
