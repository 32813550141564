import * as types from "./mutation-types";
import LocalStorageService from "SC/services/localStorage";
import LocalStorageKeys from "@/constants/localstorage";


// initial state
const state = {
  activeAccountType: LocalStorageService.get(LocalStorageKeys.CUSTOM_FIELDS_ACTIVE_ACCOUNT_TYPE),
};
const mutations = {
  setActiveAccountType(state, type) {
    state.activeAccountType = type;
  },
};
const actions = {
  setActiveAccountType({ commit }, type) {
    commit("setActiveAccountType", type);
    LocalStorageService.set(LocalStorageKeys.CUSTOM_FIELDS_ACTIVE_ACCOUNT_TYPE, type);
  },
};
const getters = {
  activeAccountType: (state) => state.activeAccountType,
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
