import _get from 'lodash/get'
import _omit from 'lodash/omit'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'
import axios from 'axios'

const Service = {
    initialized: false,
    init(apiKey, VueInstance, appVersion) {
        if (this.initialized) {
            console.warn('Bugsnag: Already Initialized')
        }

        Bugsnag.start({
            appVersion,
            apiKey: apiKey,
            plugins: [new BugsnagPluginVue()],
            redactedKeys: [
                'token',
                'password',
                'Authorization'
            ]
        })

        BugsnagPerformance.start({ apiKey: apiKey });

        Bugsnag.getPlugin('vue')
            .installVueErrorHandler(VueInstance);

        this.attachAxios();

        console.log('Bugsnag: Initialized')

        this.initialized = true;
    },
    attachAxios() {
        axios.interceptors.response.use(function (response) {            
            return response;
        }, function (error) {  
            let instanceError = (error instanceof Error) ? error :
            ((error && error.message) ? (new Error(error.message)) : new Error(error.toString()));

            Bugsnag.notify(instanceError, function (event) {                
                let config = _get(error, 'config', {});                
                let headers = _get(config, 'headers', {});
                
                let data = _get(config, 'data'); 
                try {
                    data = JSON.parse(data);                                  
                } catch (error) {                    
                    // simply not a json string, leave it as is.
                }

                event.addMetadata('axiosResponse', error.response);
                event.addMetadata('axiosRequest', error.request);
                event.addMetadata('axiosError', {
                    error: _omit(error, 'config.data')
                });

                event.addMetadata('axiosRequestConfig', {                
                    data,                                      
                    headers
                });                
            })                               

            return Promise.reject(error);
        });      
    },
    setPath(path) {
        if (!this.initialized) {
            return;
        }

        Bugsnag.setContext(path);
    },
    setUser(user) {
        if (!this.initialized) {
            return;
        }

        let userId = _get(user, 'userId');
        let userName = _get(user, 'userName');
        let firstName = _get(user, 'firstName');
        let lastName = _get(user, 'lastName');
        let fullName = `${firstName} ${lastName}`;

        console.log('Bugsnag: Setting user', {
            userId,
            userName,
            fullName
        });

        Bugsnag.setUser(userId, userName, `${firstName} ${lastName}`);
    },
    notify(message) {
        if (!this.initialized) {
            return;
        }

        Bugsnag.notify(new Error(message));
    }
};

export default Service;